import {Helmet} from "react-helmet"
import shareImg from "../assets/images/meta/share.png"
import Background from "../components/Background/Background"
import * as React from "react"

const PrivacyPage = ({location}) => {
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Privacy | Nocturne X</title>
                <meta name="description"
                      content="Enter an otherwordly forest of sound and light on planet Nocturne X in this transformative multi-sensory art experience, where the alien environment reacts to your every touch and movement."/>
                <meta property="og:image" content={shareImg}/>
                <link rel="canonical" href="https://nocturne-x.com"/>
            </Helmet>

            <Background/>

            <div className="Section" id="explore">
                <div className="Container">
                    <div className="Container__wrapper">
                        <h2 className="Text--preheading Text--teal">
                            Last updated: August 23, 2021
                        </h2>
                        <h1 className="Text--heading Text--teal">
                            Privacy Policy
                        </h1>
                        <div className="Legal">
                            <h2>
                                1. Introduction
                            </h2>
                            <p>
                                Welcome to <strong>Numina Studio, Inc.</strong>
                            </p>
                            <p>
                                Numina Studio, Inc. (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;)
                                operates nocturne-x.com (hereinafter referred to as &ldquo;
                                <strong>Service</strong>&rdquo;).
                            </p>
                            <p>
                                Our Privacy Policy governs your visit to nocturne-x.com, and explains how we
                                collect, safeguard and disclose information that results from your use of our
                                Service.
                            </p>
                            <p>
                                We use your data to provide and improve Service. By using Service, you agree to the
                                collection and use of information in accordance with this policy. Unless otherwise
                                defined in this Privacy Policy, the terms used in this Privacy Policy have the same
                                meanings as in our Terms and Conditions.
                            </p>
                            <p>
                                Our Terms and Conditions (&ldquo;<strong>Terms</strong>&rdquo;) govern all use of our
                                Service and together with the Privacy Policy constitutes your agreement with us (&ldquo;
                                <strong>agreement</strong>&rdquo;).
                            </p>

                            <h2>
                                2. Definitions
                            </h2>
                            <p>
                                <strong>SERVICE</strong> means the nocturne-x.com website operated
                                by Numina Studio, Inc.
                            </p>
                            <p>
                                <strong>PERSONAL DATA</strong> means data about a living individual who can be
                                identified from those data (or from those and other information either in our possession
                                or likely to come into our possession).
                            </p>
                            <p>
                                <strong>USAGE DATA</strong> is data collected automatically either generated by the
                                use of Service or from Service infrastructure itself (for example, the duration of a
                                page visit).
                            </p>
                            <p>
                                <strong>COOKIES</strong> are small files stored on your device (computer or mobile
                                device).
                            </p>
                            <p>
                                <strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone
                                or jointly or in common with other persons) determines the purposes for which and the
                                manner in which any personal data are, or are to be, processed. For the purpose of this
                                Privacy Policy, we are a Data Controller of your data.
                            </p>
                            <p>
                                <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal
                                person who processes the data on behalf of the Data Controller. We may use the services
                                of various Service Providers in order to process your data more effectively.
                            </p>
                            <p>
                                <strong>DATA SUBJECT</strong> is any living individual who is the subject of
                                Personal Data.
                            </p>
                            <p>
                                <strong>THE USER</strong> is the individual using our Service. The User corresponds
                                to the Data Subject, who is the subject of Personal Data.
                            </p>

                            <h2>
                                3. Information Collection and Use
                            </h2>
                            <p>
                                We collect several different types of information for various purposes to provide and
                                improve our Service to you.
                            </p>

                            <h2>
                                4. Types of Data Collected
                            </h2>
                            <p>
                                <strong>Personal Data</strong>
                            </p>
                            <p>
                                While using our Service, we may ask you to provide us with certain personally
                                identifiable information that can be used to contact or identify you (&ldquo;<strong>Personal
                                    Data</strong>&rdquo;). Personally identifiable information may include, but is not
                                limited to:
                            </p>
                            <p>
                                (a)  Email address
                            </p>
                            <p>
                                (b)  Cookies and Usage Data
                            </p>
                            <p>
                                We may use your Personal Data to contact you with newsletters, marketing or promotional
                                materials and other information that may be of interest to you. You may opt out of
                                receiving any, or all, of these communications from us by following the unsubscribe
                                link.
                            </p>
                            <p>
                                <strong>Usage Data</strong>
                            </p>
                            <p>
                                We may also collect information that your browser sends whenever you visit our Service or
                                when you access Service by or through a mobile device (&ldquo;<strong>Usage
                                    Data</strong>&rdquo;).
                            </p>
                            <p>
                                This Usage Data may include information such as your computer's Internet Protocol address
                                (e.g. IP address), browser type, browser version, the pages of our Service that you
                                visit, the time and date of your visit, the time spent on those pages, unique device
                                identifiers and other diagnostic data.
                            </p>
                            <p>
                                When you access Service with a mobile device, this Usage Data may include information
                                such as the type of mobile device you use, your mobile device unique ID, the IP address
                                of your mobile device, your mobile operating system, the type of mobile Internet browser
                                you use, unique device identifiers and other diagnostic data.
                            </p>
                            <p>
                                <strong>Tracking Cookies Data</strong>
                            </p>
                            <p>
                                We use cookies and similar tracking technologies to track the activity on our Service and
                                we hold certain information.
                            </p>
                            <p>
                                Cookies are files with a small amount of data which may include an anonymous unique
                                identifier. Cookies are sent to your browser from a website and stored on your device.
                                Other tracking technologies are also used such as beacons, tags and scripts to collect
                                and track information and to improve and analyze our Service.
                            </p>
                            <p>
                                You can instruct your browser to refuse all cookies or to indicate when a cookie is being
                                sent. However, if you do not accept cookies, you may not be able to use some portions of
                                our Service.
                            </p>
                            <p>
                                Examples of Cookies we use:
                            </p>
                            <p>
                                (a)  <strong>Session Cookies:</strong> We use Session Cookies to operate
                                our Service.
                            </p>
                            <p>
                                (b)  <strong>Preference Cookies:</strong> We use Preference Cookies to
                                remember your preferences and various settings.
                            </p>
                            <p>
                                (c)  <strong>Security Cookies:</strong> We use Security Cookies for
                                security purposes.
                            </p>
                            <p>
                                (d)  <strong>Advertising Cookies:</strong> Advertising Cookies are used
                                to serve you with advertisements that may be relevant to you and your interests.
                            </p>

                            <h2>
                                5. Use of Data
                            </h2>
                            <p>
                                Numina Studio, Inc. uses the collected data for various purposes:
                            </p>
                            <p>
                                (a)  to provide and maintain our Service;
                            </p>
                            <p>
                                (b)  to notify you about changes to our Service;
                            </p>
                            <p>
                                (c)  to allow you to participate in interactive features of our Service when
                                you choose to do so;
                            </p>
                            <p>
                                (d)  to provide customer support;
                            </p>
                            <p>
                                (e)  to gather analysis or valuable information so that we can improve our
                                Service;
                            </p>
                            <p>
                                (f)  to monitor the usage of our Service;
                            </p>
                            <p>
                                (g)  to detect, prevent and address technical issues;
                            </p>
                            <p>
                                (h)  to fulfill any other purpose for which you provide it;
                            </p>
                            <p>
                                (i)  to carry out our obligations and enforce our rights arising from
                                any contracts entered into between you and us, including for billing and collection;
                            </p>
                            <p>
                                (j)  to provide you with notices about your account and/or subscription,
                                including expiration and renewal notices, email-instructions, etc.;
                            </p>
                            <p>
                                (k)  to provide you with news, special offers and general information about
                                other goods, services and events which we offer that are similar to those that you have
                                already purchased or enquired about unless you have opted not to receive such
                                information;
                            </p>
                            <p>
                                (l)  in any other way we may describe when you provide the
                                information;
                            </p>
                            <p>
                                (m) for any other purpose with your consent.
                            </p>

                            <h2>
                                6. Retention of Data
                            </h2>
                            <p>
                                We will retain your Personal Data only for as long as is necessary for the purposes set
                                out in this Privacy Policy. We will retain and use your Personal Data to the extent
                                necessary to comply with our legal obligations (for example, if we are required to
                                retain your data to comply with applicable laws), resolve disputes, and enforce our
                                legal agreements and policies.
                            </p>
                            <p>
                                We will also retain Usage Data for internal analysis purposes. Usage Data is generally
                                retained for a shorter period, except when this data is used to strengthen the security
                                or to improve the functionality of our Service, or we are legally obligated to retain
                                this data for longer time periods.
                            </p>

                            <h2>
                                7. Transfer of Data
                            </h2>
                            <p>
                                Your information, including Personal Data, may be transferred to &ndash; and maintained
                                on &ndash; computers located outside of your state, province, country or other
                                governmental jurisdiction where the data protection laws may differ from those of your
                                jurisdiction.
                            </p>
                            <p>
                                If you are located outside United States and choose to provide information to us, please
                                note that we transfer the data, including Personal Data, to United States and process it
                                there.
                            </p>
                            <p>
                                Your consent to this Privacy Policy followed by your submission of such information
                                represents your agreement to that transfer.
                            </p>
                            <p>
                                Numina Studio, Inc. will take all the steps reasonably necessary to ensure that your
                                data is treated securely and in accordance with this Privacy Policy and no transfer of
                                your Personal Data will take place to an organisation or a country unless there are
                                adequate controls in place including the security of your data and other personal
                                information.
                            </p>

                            <h2>
                                8. Disclosure of Data
                            </h2>
                            <p>
                                We may disclose personal information that we collect, or you provide:
                            </p>
                            <p>
                                (a)  <strong>Disclosure for Law Enforcement.</strong>
                            </p>
                            <p>
                                Under certain circumstances, we may be required to disclose your Personal Data if
                                required to do so by law or in response to valid requests by public authorities.
                            </p>
                            <p>
                                (b)  <strong>Business Transaction.</strong>
                            </p>
                            <p>
                                If we or our subsidiaries are involved in a merger, acquisition or asset sale, your
                                Personal Data may be transferred.
                            </p>
                            <p>
                                (c)  <strong>Other cases. We may disclose your information also:</strong>
                            </p>
                            <p>
                                (i)  to our subsidiaries and affiliates;
                            </p>

                            <h2>9. Security of Data</h2>
                            <p>
                                The security of your data is important to us but remember that no method of transmission
                                over the Internet or method of electronic storage is 100% secure. While we strive to use
                                commercially acceptable means to protect your Personal Data, we cannot guarantee its
                                absolute security.
                            </p>

                            <h2>10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
                            <p>
                                If you are a resident of the European Union (EU) and European Economic Area (EEA), you
                                have certain data protection rights, covered by GDPR. &ndash; See more at
                                https://eur-lex.europa.eu/eli/reg/2016/679/oj
                            </p>
                            <p>
                                We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use
                                of your Personal Data.
                            </p>
                            <p>
                                If you wish to be informed what Personal Data we hold about you and if you want it to be
                                removed from our systems, please email us at info@numina.studio.
                            </p>
                            <p>
                                In certain circumstances, you have the following data protection rights:
                            </p>
                            <p>
                                (a)  the right to access, update or to delete the information we have on
                                you;
                            </p>
                            <p>
                                (b)  the right of rectification. You have the right to have your information
                                rectified if that information is inaccurate or incomplete;
                            </p>
                            <p>
                                (c)  the right to object. You have the right to object to our processing of
                                your Personal Data;
                            </p>
                            <p>
                                (d)  the right of restriction. You have the right to request that we restrict
                                the processing of your personal information;
                            </p>
                            <p>
                                (e)  the right to data portability. You have the right to be provided with a
                                copy of your Personal Data in a structured, machine-readable and commonly used
                                format;
                            </p>
                            <p>
                                (f)  the right to withdraw consent. You also have the right to
                                withdraw your consent at any time where we rely on your consent to process your personal
                                information;
                            </p>
                            <p>
                                Please note that we may ask you to verify your identity before responding to such
                                requests. Please note, we may not able to provide Service without some necessary
                                data.
                            </p>
                            <p>
                                You have the right to complain to a Data Protection Authority about our collection and
                                use of your Personal Data. For more information, please contact your local data
                                protection authority in the European Economic Area (EEA).
                            </p>

                            <h2>
                                11. Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)
                            </h2>
                            <p>
                                CalOPPA is the first state law in the nation to require commercial websites and online
                                services to post a privacy policy. The law&rsquo;s reach stretches well beyond
                                California to require a person or company in the United States (and conceivable the
                                world) that operates websites collecting personally identifiable information from
                                California consumers to post a conspicuous privacy policy on its website stating exactly
                                the information being collected and those individuals with whom it is being shared, and
                                to comply with this policy. &ndash; See more at:
                                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                            </p>
                            <p>
                                According to CalOPPA we agree to the following:
                            </p>
                            <p>
                                (a)  users can visit our site anonymously;
                            </p>
                            <p>
                                (b)  our Privacy Policy link includes the word &ldquo;Privacy&rdquo;, and can
                                easily be found on the page specified above on the home page of our website;
                            </p>
                            <p>
                                (c)  users will be notified of any privacy policy changes on our Privacy
                                Policy Page;
                            </p>
                            <p>
                                (d)  users are able to change their personal information by emailing us at
                                info@numina.studio.
                            </p>
                            <p>
                                Our Policy on &ldquo;Do Not Track&rdquo; Signals:
                            </p>
                            <p>
                                We honor Do Not Track signals and do not track, plant cookies, or use advertising when a
                                Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in
                                your web browser to inform websites that you do not want to be tracked.
                            </p>
                            <p>
                                You can enable or disable Do Not Track by visiting the Preferences or Settings page of
                                your web browser.
                            </p>

                            <h2>
                                12. Service Providers
                            </h2>
                            <p>
                                We may employ third party companies and individuals to facilitate our Service (&ldquo;
                                <strong>Service Providers</strong>&rdquo;), provide Service on our behalf, perform
                                Service-related services or assist us in analysing how our Service is used.
                            </p>
                            <p>
                                These third parties have access to your Personal Data only to perform these tasks on our
                                behalf and are obligated not to disclose or use it for any other purpose.
                            </p>

                            <h2>
                                13. Analytics
                            </h2>
                            <p>
                                We may use third-party Service Providers to monitor and analyze the use of our
                                Service.
                            </p>
                            <p>
                                Google Analytics
                            </p>
                            <p>
                                Google Analytics is a web analytics service offered by Google that tracks and reports
                                website traffic. Google uses the data collected to track and monitor the use of our
                                Service. This data is shared with other Google services. Google may use the collected
                                data to contextualise and personalise the ads of its own advertising network.
                            </p>
                            <p>
                                For more information on the privacy practices of Google, please visit the Google Privacy
                                Terms web page: https://policies.google.com/privacy?hl=en
                            </p>
                            <p>
                                We also encourage you to review the Google's policy for safeguarding your data:
                                https://support.google.com/analytics/answer/6004245.
                            </p>

                            <h2>
                                14. CI/CD tools
                            </h2>
                            <p>
                                We may use third-party Service Providers to automate the development process of our
                                Service.
                            </p>
                            <p>
                                <strong>GitHub</strong>
                            </p>
                            <p>
                                GitHub is provided by GitHub, Inc.
                            </p>
                            <p>
                                GitHub is a development platform to host and review code, manage projects, and build
                                software.
                            </p>
                            <p>
                                For more information on what data GitHub collects for what purpose and how the protection
                                of the data is ensured, please visit GitHub Privacy Policy page:<a
                                    href="https://help.github.com/en/articles/github-privacy-statement">https://help.github.com/en/articles/github-privacy-statement.</a>
                            </p>

                            <h2>
                                15. Behavioral Remarketing
                            </h2>
                            <p>
                                Numina Studio, Inc. uses remarketing services to advertise on third party websites
                                to you after you visited our Service. We and our third-party vendors use cookies to
                                inform, optimise and serve ads based on your past visits to our Service.
                            </p>
                            <p>
                                Google Ads (AdWords)
                            </p>
                            <p>
                                Google Ads (AdWords) remarketing service is provided by Google Inc.
                            </p>
                            <p>
                                You can opt-out of Google Analytics for Display Advertising and customize the Google
                                Display Network ads by visiting the Google Ads Settings page:
                                http://www.google.com/settings/ads
                            </p>
                            <p>
                                Google also recommends installing the Google Analytics Opt-out Browser
                                Add-on &ndash; https://tools.google.com/dlpage/gaoptout &ndash; for your web browser.
                                Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent
                                their data from being collected and used by Google Analytics.
                            </p>
                            <p>
                                For more information on the privacy practices of Google, please visit the Google Privacy
                                Terms web page: https://policies.google.com/privacy?hl=en
                            </p>

                            <h2>
                                16. Payments
                            </h2>
                            <p>
                                We may provide paid products and/or services within Service. In that case, we use
                                third-party services for payment processing (e.g. payment processors).
                            </p>
                            <p>
                                We will not store or collect your payment card details. That information is provided
                                directly to our third-party payment processors whose use of your personal information is
                                governed by their Privacy Policy. These payment processors adhere to the standards set
                                by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of
                                brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help
                                ensure the secure handling of payment information.
                            </p>
                            <p>
                                The payment processors we work with are:
                            </p>
                            <p>
                                PayPal or Braintree:
                            </p>
                            <p>
                                Their Privacy Policy can be viewed
                                at https://www.paypal.com/webapps/mpp/ua/privacy-full
                            </p>
                            <p>
                                Stripe:
                            </p>
                            <p>
                                Their Privacy Policy can be viewed at: https://stripe.com/us/privacy
                            </p>

                            <h2>
                                17. Links to Other Sites
                            </h2>
                            <p>
                                Our Service may contain links to other sites that are not operated by us. If you click a
                                third party link, you will be directed to that third party's site. We strongly advise
                                you to review the Privacy Policy of every site you visit.
                            </p>
                            <p>
                                We have no control over and assume no responsibility for the content, privacy policies or
                                practices of any third party sites or services.
                            </p>

                            <h2>
                                18. Children's Privacy
                            </h2>
                            <p>
                                Our Services are not intended for use by children under the age of 13 (&ldquo;
                                <strong>Children</strong>&rdquo;).
                            </p>
                            <p>
                                We do not knowingly collect personally identifiable information from Children under 13.
                                If you become aware that a Child has provided us with Personal Data, please contact us.
                                If we become aware that we have collected Personal Data from Children without
                                verification of parental consent, we take steps to remove that information from our
                                servers.
                            </p>

                            <h2>
                                19. Changes to This Privacy Policy
                            </h2>
                            <p>
                                We may update our Privacy Policy from time to time. We will notify you of any changes by
                                posting the new Privacy Policy on this page.
                            </p>
                            <p>
                                We will let you know via email and/or a prominent notice on our Service, prior to the
                                change becoming effective and update &ldquo;effective date&rdquo; at the top of this
                                Privacy Policy.
                            </p>
                            <p>
                                You are advised to review this Privacy Policy periodically for any changes. Changes to
                                this Privacy Policy are effective when they are posted on this page.
                            </p>

                            <h2>
                                20. Contact Us
                            </h2>
                            <p>
                                If you have any questions about this Privacy Policy, please contact us:
                            </p>
                            <p>
                                By email: info@numina.studio.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default PrivacyPage